import './App.css';
import { useEffect, useState, Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import * as commonServices from './Services/common';
import * as storageConstants from './Constants/storageConstant';
import { isNullOrEmpty } from './Utils/stringUtils';
import { ATTENDANCE, CLIENTS, DASHBOARD, DEPARTMENTS, REPORTS, HELP, NOT_APPLICABLE, PROFILE, SUPER_ADMIN, SYSTEM_SETTINGS, USER_AND_ROLES } from './Constants/appConstants';

import ProtectedRoute from './Protected/Protected';
import MainLoader from './Components/_widgets/Loader/MainLoader';
import PrivacyPolicy from './Components/Privacy/Privacy';
import Terms from './Components/Privacy/Terms';
import { warn } from './Wrapper/toast/toast';
import { getProfileData } from './Services/ApiHelper';

const LoginLayout = lazy(() => import('./Components/Layouts/LoginLayout'));
const HomeLayout = lazy(() => import('./Components/Layouts/HomeLayout'));
const NotFound = lazy(() => import('./Components/NotFound/NotFound'));
const SuperAdminLogin = lazy(() => import('./Components/Login/SuperAdminLogin'));
const Dashboard = lazy(() => import('./Components/Dashboard/Dashboard'));
const Clients = lazy(() => import('./Components/Clients/Clients'));
const AdminLogin = lazy(() => import('./Components/Login/AdminLogin'));
const ResetPassword = lazy(() => import('./Components/ResetPassword/ResetPassword'));
const Roles = lazy(() => import('./Components/Roles/Roles'));
const Users = lazy(() => import('./Components/Users/Users'));
const Departments = lazy(() => import('./Components/Departments/Departments'));
const DepartmentDetails = lazy(() => import('./Components/Departments/DepartmentDetails'));
const Attendance = lazy(() => import('./Components/Attandance/Attendance'));
const Reports = lazy(() => import('./Components/Reports/Reports'));
const SystemSettings = lazy(() => import('./Components/SystemSettings/SystemSettings'));
const Help = lazy(() => import('./Components/Help/Help'));
const ChangePassword = lazy(() => import('./Components/ChangePassword/ChangePassword'));
const Profile = lazy(() => import('./Components/Profile/Profile'));

function App() {
  const location = useLocation();
  const [mainLoading, setMainLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  useEffect(() => {
    const data = commonServices.getMasterData();
    const sysData = commonServices.getLocalData(storageConstants.SYSTEM_DATA);
    const userData = commonServices.getLocalData(storageConstants.USER_DATA);

    if (isNullOrEmpty(data)) {
      commonServices.fetchMasterData(setMainLoading);
    }

    if (userData?.auth && isNullOrEmpty(sysData)) {
      commonServices.fetchSystemData(setMainLoading);
    }

    if (!isNullOrEmpty(userData?.auth)
      && !commonServices.isSuperAdmin()
      && (isNullOrEmpty(userData?.color)
        || isNullOrEmpty(userData?.name)
        || isNullOrEmpty(userData?.image)
        || isNullOrEmpty(userData?.code)
        || isNullOrEmpty(userData?.role))) {
      commonServices.clearLocalStorageData();
      window.location.href = '/';
    }

    !isNullOrEmpty(userData?.color)
      && (!isNullOrEmpty(userData?.auth) || !isNullOrEmpty(userData?.code))
      && userData?.role !== SUPER_ADMIN
      && commonServices.setPrimarySecondaryColors(userData?.color);

  }, [location.pathname]);

  useEffect(() => {
    !commonServices.isSuperAdmin() && commonServices.getLocalData(storageConstants.USER_DATA)?.auth && getProfile();
  }, [commonServices.getLocalData(storageConstants.USER_DATA)?.auth]);

  const getProfile = async () => {
    setProfileLoading(true);
    const resp = await getProfileData();
    if (resp?.isSuccess) {
      commonServices.storeLocalData(storageConstants.PERMISSIONS, resp?.data?.role?.Permissions ?? []);
      commonServices.storeLocalData(storageConstants.PROFILE, { unverifiedUserCount: resp?.data?.unverifiedUserCount });
      commonServices.storeRolesInLocal(resp?.data?.roleList ?? []);
      const depts = resp?.data?.departments?.map(d => ({ label: d?.name, value: d?.id })) || [];
      commonServices.storeLocalData(storageConstants.DEPARTMENTS, depts);
    } else {
      warn(resp?.message);
    }
    setProfileLoading(false);
  }

  return (
    <div className="App">

      {process.env.REACT_APP_ENV !== "production" && (
        <div className="ribbon ribbon-top-left">
          <span>{process.env.REACT_APP_ENV}</span>
        </div>
      )}

      <Suspense fallback={<MainLoader />}>
        <ToastContainer />

        {mainLoading || profileLoading
          ? <MainLoader />
          : <Routes>
            <Route element={<LoginLayout />} >
              <Route exact path='/' element={<ProtectedRoute pageName={NOT_APPLICABLE} Component={<AdminLogin location={location} />} />} />
              <Route exact path='/admin' element={<ProtectedRoute pageName={NOT_APPLICABLE} Component={<SuperAdminLogin location={location} />} />} />
              <Route exact path='/reset-password' element={<ResetPassword location={location} />} />
              <Route exact path='/privacy' element={<PrivacyPolicy location={location} />} />
              <Route exact path='/terms' element={<Terms location={location} />} />
              <Route exact path="/*" element={<NotFound />} />
            </Route>

            <Route element={<HomeLayout />}>
              <Route exact path="/dashboard" element={<ProtectedRoute pageName={DASHBOARD} Component={<Dashboard />} />} />
              <Route exact path="/clients" element={<ProtectedRoute pageName={CLIENTS} Component={<Clients />} />} />
              <Route exact path="/roles" element={<ProtectedRoute pageName={USER_AND_ROLES} Component={<Roles />} />} />
              <Route exact path="/users" element={<ProtectedRoute pageName={USER_AND_ROLES} Component={<Users />} />} />
              <Route exact path="/departments" element={<ProtectedRoute pageName={DEPARTMENTS} Component={<Departments />} />} />
              <Route exact path="/departments/:departmentID" element={<ProtectedRoute pageName={DEPARTMENTS} Component={<DepartmentDetails />} />} />
              <Route exact path="/teacher-attendance" element={<ProtectedRoute pageName={ATTENDANCE} Component={<Attendance />} />} />
              <Route exact path="/student-attendance" element={<ProtectedRoute pageName={ATTENDANCE} Component={<Attendance />} />} />
              <Route exact path="/reports" element={<ProtectedRoute pageName={REPORTS} Component={<Reports />} />} />
              <Route exact path="/system-settings" element={<ProtectedRoute pageName={SYSTEM_SETTINGS} Component={<SystemSettings />} />} />
              <Route exact path="/help" element={<ProtectedRoute pageName={HELP} Component={<Help />} />} />
              <Route exact path="/profile" element={<ProtectedRoute pageName={PROFILE} Component={<Profile />} />} />
              <Route exact path="/change-password" element={<ProtectedRoute pageName={PROFILE} Component={<ChangePassword />} />} />
            </Route>
          </Routes>}
      </Suspense>
    </div>
  );
}

export default App;
